<template>
    <div class="main-container">
        <img :src="require('../assets/bg2.jpg')" class="bg">
        <div id="loading" v-if="showLoading">
            <div class="content">
        
                <v-icon name="spinner" pulse scale="3" />

            </div>
        </div>
        <div class="sidebar" v-if="sideBar">
            <SideBar @close="openSidebar()" :active="'dashboard'"/>
        </div>
        <div class="d-flex flex-wrap wrapper">
            <div class="main-body">
                <div class="topbar d-flex flex-wrap justify-content-between mb-5">
                    <div class="d-flex justify-content-between">
                        <button class="sidebar-toggler text-white my-auto mr-3" type="button" @click="openSidebar">
                            <v-icon name="chevron-right" />
                        </button>
                        <!-- <input class="form-control mr-sm-2 search-bar my-auto" type="search" placeholder="Search Profile ID" aria-label="Search"> -->
                    </div>
                    <div class="left-section d-flex flex-wrap justify-content-end align-items-center text-white">
                        <h6 class="my-auto" style="margin-right:3em;cursor:pointer;" @click="logout">Logout</h6>
                        <img :src="require('../assets/logout.png')" alt="" class="topbar-img my-auto" @click="logout">
                    </div>
                </div>
                <div class="main-wallet d-flex flex-wrap justify-content-around" v-if="state == 'main-wallet'">
                    <div>
                        <div class="webview-id-card mb-3">
                            <div class="d-flex flex-wrap justify-content-between mb-4">
                                <img :src="require('../assets/id-logo.png')" style="width:15em;">
                                <div class="text-right my-auto">
                                    <h6 style="color:#0567d7;">BLOCK ID NO.</h6>
                                    <h5 style="color:white;">{{ init.profile.entityId }}</h5>
                                </div>
                                <div class="text-right my-auto">
                                    <h6 style="color:#0567d7;">CONFIDENCE LEVEL</h6>
                                    <h5 style="color:white;">{{confidenceLevel}}%</h5>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap justify-content-between">
                                <!-- <div style="width:15%;">
                                    <img :src="pfpString != '' ? `data:image/png;base64,${pfpString}` : require('../assets/Asset 29.png')" style="width:100%;">
                                </div> -->

                                 <div class="profile-picture">
                                  <img :src="pfpString != '' ? `data:image/png;base64,${pfpString}` : require('../assets/Asset 29.png')" style="width:100%;">
                                </div>


                                <div style="width:65%;">
                                    <h1 class="last-name" style="color:#0567d7;margin-bottom:0;font-weight:bold;">{{ init.profile.lastName }}</h1>
                                    <h1 class="first-name" style="color:#0567d7;">{{ init.profile.firstName }}</h1>
                                    <div class="d-flex flex-wrap mt-3">
                                        <div style="width:33%">
                                            <h6 style="color:#fff;margin-bottom:0;">DATE OF BIRTH</h6>
                                            <h4 style="color:#0567d7;">{{ init.profile.birthYear }}-{{ init.profile.birthMm }}-{{ init.profile.birthDd }}</h4>
                                        </div>
                                        <div style="width:33%">
                                            <h6 style="color:#fff;margin-bottom:0;">GENDER</h6>
                                            <h4 style="color:#0567d7;">{{ init.profile.gender }}</h4>
                                        </div>
                                        <div style="width:33%">
                                            <h6 style="color:#fff;margin-bottom:0;">CIVIL STATUS</h6>
                                            <h4 style="color:#0567d7;">{{ getMaritalStatusString(init.profile.maritalStatus) }}</h4>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap mt-3">
                                        <div style="width:33%">
                                            <h6 style="color:#fff;margin-bottom:0;">CITIZENSHIP</h6>
                                            <h4 style="color:#0567d7;">{{ init.profile.presentAddress.country }}</h4>
                                        </div>
                                        <div style="width:33%">
                                            <h6 style="color:#fff;margin-bottom:0;">SHAREABLE DATA</h6>
                                            <h4 style="color:#0567d7;">{{ poids.length + 2 }} OF 20</h4>
                                        </div>
                                        <div style="width:33%">
                                            <h6 style="color:#fff;margin-bottom:0;">VERIFIED DATA</h6>
                                            <h4 style="color:#0567d7;">{{ totalVerified }} OF {{ poids.length + 2 }}</h4>
                                        </div>
                                        
                                        <div>
                                        </div>
                                    </div>
                                  
                                </div>
                                <div style="width:15%;">
                                </div>
                            </div>
                            <div>
                                <div class="d-flex flex-wrap mt-4">
                                    <div style="width:33%">
                                        <h6 style="color:#fff;margin-bottom:0;">DATE OF REGISTRATION</h6>
                                        <h6 style="color:#0567d7;">{{ init.profile.signupDate.substring(0, 10) }}</h6>
                                    </div>
                                   
                                    <div style="width:33%">
                                        <h6 style="color:#fff;margin-bottom:0;">FIRST CONFIRMATION</h6>
                                        <h6 style="color:#0567d7;">{{ init.profile.confirmation }}</h6>
                                    </div>
                                    <div style="width:33%">
                                        <h6 style="color:#fff;margin-bottom:0;">LATEST CONFIRMATION</h6>
                                        <h6 style="color:#0567d7;">{{ init.profile.confirmation }}</h6>
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap mt-3">
                                   
                                   
                                    <div style="width:33%">
                                        <h6 style="color:#fff;margin-bottom:0;"># OF PEER-TO-PEER VERIFICATIONS</h6>
                                        <h6 style="color:#0567d7;">{{ peerToPeerCount }}</h6>
                                    </div>
                                    <div style="width:33%">
                                        <h6 style="color:#fff;margin-bottom:0;"># OF INSTITUTIONAL VERIFICATIONS</h6>
                                        <h6 style="color:#0567d7;">{{ businessCount }}</h6>
                                    </div>
                                    <div style="width:33%">
                                        <h6 style="color:#fff;margin-bottom:0;"># OF GOVERNMENT VERIFICATIONS</h6>
                                        <h6 style="color:#0567d7;">{{ govCount }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="mobile-id-card">
                            
                        </div>
                        <div class="text-center">
                                
                                <button class="btn btn-main mb-5 p-3" @click="share">SHARE YOUR DATA AND GET VERIFIED <img :src="require('../assets/Asset 131.png')" alt="" style="width:7em;"> </button>
                        </div>
                        
                    </div>
                    <div class="history">

                            <h6 class="text-white">LIST OF VERIFIED DATA ASSETS</h6>
                                <div class="d-flex flex-wrap m-4">
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/bluedot.png')" alt="" class="sidebar-link-img"> <span >Full name</span> <span style="color: lightgreen"> {{ verifiedCount['FULLNAME'] }} </span> |  <span style="color: red"> {{ rejectedCount['FULLNAME'] }}  </span></button>
                                    </div>
                                   
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/face.png')" alt="" class="sidebar-link-img"> <span>Face</span> <span style="color: lightgreen"> {{ verifiedCount['SELFIE'] }} </span>  | <span style="color: red"> {{ rejectedCount['SELFIE'] }}  </span></button>
                                    </div>
                                    
                                </div>
                                <div class="d-flex flex-wrap m-4">
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/bluedot.png')" alt="" class="sidebar-link-img"> <span >Birthdate</span> <span style="color: lightgreen"> {{ verifiedCount['BDAY'] }} </span> |  <span style="color: red"> {{ rejectedCount['BDAY'] }}  </span></button>
                                    </div>
                                   
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/others.png')" alt="" class="sidebar-link-img"> <span>Civil Status</span> <span style="color: lightgreen"> {{ verifiedCount['CIVILSTATUS'] }} </span> | <span style="color: red"> {{ rejectedCount['CIVILSTATUS'] }}  </span></button>
                                    </div>
                                    
                                </div>
                                <div class="d-flex flex-wrap m-4">
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/others.png')" alt="" class="sidebar-link-img"> <span>Email Address</span> <span style="color: lightgreen"> {{ verifiedCount['EMAIL'] }} </span> |  <span style="color: red"> {{ rejectedCount['EMAIL'] }}  </span></button>
                                    </div>
                                   
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/others.png')" alt="" class="sidebar-link-img"> <span>Mobile Number</span> <span style="color: lightgreen"> {{ verifiedCount['MOBILE'] }} </span> |  <span style="color: red"> {{ rejectedCount['MOBILE'] }}  </span></button>
                                    </div>
                                    
                                </div>
                                <div class="d-flex flex-wrap m-4">
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/gender.png')" alt="" class="sidebar-link-img"> <span>Gender</span> <span style="color: lightgreen"> {{ verifiedCount['GENDER'] }} </span>  | <span style="color: red"> {{ rejectedCount['GENDER'] }}  </span></button>
                                    </div>
                                   
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/passport.png')" alt="" class="sidebar-link-img"> <span>Citizenship</span> <span style="color: lightgreen"> {{ verifiedCount['CITIZENSHIP'] }} </span> |  <span style="color: red"> {{ rejectedCount['CITIZENSHIP'] }}  </span></button>
                                    </div>
                                    
                                </div>
                                <div class="d-flex flex-wrap m-4">
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/id.png')" alt="" class="sidebar-link-img"> <span>Primary ID</span>  <span style="color: lightgreen"> {{ verifiedCount['PASSPORTNUMBER'] }} </span> |  <span style="color: red"> {{ rejectedCount['PASSPORTNUMBER'] }}  </span></button>
                                    </div>
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/proof of address.png')" alt="" class="sidebar-link-img"> <span>POA</span> <span style="color: lightgreen">0</span> |  <span style="color: red">0</span></button>
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap m-4">
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/home.png')" alt="" class="sidebar-link-img"> <span>Home Asset</span> <span style="color: lightgreen"> {{ verifiedCount['HOMEASSET'] }} </span>  |  <span style="color: red"> {{ rejectedCount['HOMEASSET'] }}  </span></button>
                                    </div>
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/education.png')" alt="" class="sidebar-link-img"> <span>Education </span>  <span style="color: lightgreen"> {{ verifiedCount['EDUCATION'] }} </span>   |  <span style="color: red"> {{ rejectedCount['EDUCATION'] }}  </span></button>
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap m-4">
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/employment.png')" alt="" class="sidebar-link-img"> <span>Employment</span> <span style="color: lightgreen"> {{ verifiedCount['EMPLOYMENTCERT'] }} </span>  | <span style="color: red"> {{ rejectedCount['EMPLOYMENTCERT'] }}  </span></button>
                                    </div>
                                   
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/vehicle.png')" alt="" class="sidebar-link-img"> <span>Vehicle Asset</span> <span style="color: lightgreen"> {{ verifiedCount['VEHICLEASSET'] }} </span>  |  <span style="color: red"> {{ rejectedCount['VEHICLEASSET'] }}  </span></button>
                                    </div>
                                   
                                </div>
                                <div class="d-flex flex-wrap m-4">
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/education.png')" alt="" class="sidebar-link-img"> <span>NBI</span>  <span style="color: lightgreen"> {{ verifiedCount['NBICLEARANCE'] }} </span> | <span style="color: red"> {{ rejectedCount['NBICLEARANCE'] }}  </span></button>
                                    </div>
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/education.png')" alt="" class="sidebar-link-img"> <span>Voter's ID</span> <span style="color: lightgreen"> {{ verifiedCount['VOTERSID'] }} </span> | <span style="color: red"> {{ rejectedCount['VOTERSID'] }}  </span></button>
                                    </div>
                                </div>
                                <div class="d-flex flex-wrap m-4">
                                   <div v-if="init.profile.type == 'B'"> 
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/education.png')" alt="" class="sidebar-link-img"> <span>Education</span> <span style="color: lightgreen"> {{ verifiedCount['EDUCATION'] }} </span> <span style="color: red"> {{ rejectedCount['EDUCATION'] }}  </span></button>
                                    </div>
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/education.png')" alt="" class="sidebar-link-img"> <span>Education</span> <span style="color: lightgreen">#</span> <span style="color: red">#</span></button>
                                    </div>
                                   </div>
                                    
                                </div>
                                <div class="d-flex flex-wrap m-4" v-if="init.profile.type == 'B'">
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/education.png')" alt="" class="sidebar-link-img"> <span>Education</span> <span style="color: lightgreen">#</span> <span style="color: red">#</span></button>
                                    </div>
                                   
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/education.png')" alt="" class="sidebar-link-img"> <span>Education</span> <span style="color: lightgreen">#</span> <span style="color: red">#</span></button>
                                    </div>
                                    <div style="width:50%">
                                        <button class="btn-main" style="border-radius: 6px;"> <img :src="require('../assets/education.png')" alt="" class="sidebar-link-img"> <span>Education</span> <span style="color: lightgreen">#</span> <span style="color: red">#</span></button>
                                    </div>
                                </div>
                                
                               
                          
                       <!--  <div class="history"> -->
                           
                            <!-- <div class="btn-row d-flex">
                                <button class="btn" :class="{'btn-main': activeTab == 'verifications', 'btn-cancel' : activeTab == 'rejections'}" style="width:150px;margin-right:10px;" @click="changeTab('verifications')">VERIFICATIONS</button>
                                <button class="btn" :class="{'btn-main': activeTab == 'rejections', 'btn-cancel' : activeTab == 'verifications'}"  style="width:150px;" @click="changeTab('rejections')">REJECTIONS</button>
                            </div>
                            <div class="transactions text-white mt-3" v-if="activeTab == 'verifications'">
                                <div class="transaction d-flex flex-wrap mb-3" v-for="h in historyYES" :key="h.hash">
                                    
                                    <div class="transaction-info mb-3">
                                        <h6>{{ formatDate(h.createDate) }}</h6>
                                        <h6> {{ h.idType }}</h6>
                                        <h6>Verified by User {{  h.entityId }}</h6>
                                    </div>
                                </div>
                                <h6 v-if="historyYES.length == 0">No verifications yet.</h6>
                            </div>
                            <div class="transactions text-white mt-3" v-if="activeTab == 'rejections'">
                                <div class="transaction d-flex flex-wrap mb-3" v-for="h in historyNAY" :key="h.hash">
                                
                                    <div class="transaction-info">
                                        <h6>{{ formatDate(h.createDate) }}</h6>
                                        <h6> {{ h.idType }}</h6>
                                        <h6>Rejected by User {{ h.entityId }}</h6>
                                        <button class="btn btn-main" style="width:100px;" @click="viewRejection(h.remarks)">VIEW</button>
                                    </div>
                                </div>
                                <h6 v-if="historyNAY.length == 0">No rejections yet.</h6>
                            </div> -->
                        <!-- </div> -->
                       
                    </div>
                </div>
            </div>
            <p style="color:gray; font-size: 10px; margin-left:10px;">version 1.001</p>
        </div>
        <ChangePin @close="exitModal"/>
        <ConfirmLogout />
        <ShareDetails />
        <ViewRejection :remark="remark" />
    </div>
</template>

<script>
import ChangePin from '@/components/modal/ChangePin.vue'
import ConfirmLogout from '@/components/modal/ConfirmLogout.vue'
import ShareDetails from '@/components/modal/ShareDetails.vue'
import ViewRejection from '@/components/modal/ViewRejection.vue'
import SideBar from '@/components/SideBar.vue'
import Vue from 'vue';
import VueQrcode from '@chenfengyuan/vue-qrcode'
 
Vue.component(VueQrcode.name, VueQrcode);
export default {
    components: {
        ChangePin,
        ConfirmLogout,
        ShareDetails,
        SideBar,
        ViewRejection
    },
    data() {
        return {
           
            sideBar: false,
            modalState: 'main',
            state: 'main-wallet',
            selectedAsset: {},
            noAsset: true,
            ifAddingAsset: false,
            isNft: false,
            symbol: "",
            assetInfo: {},
            subwalletInfo: {},
            showAsset: false,
            status: "UNCONFIRMED",
            showLoading: false,
            cbtFund: {},
            daoFund: {},
            pfpString: "",
            tokenId: "null",
            poids: {},
            activeTab: 'verifications',
            historyYES: [],
            historyNAY: [],
            remark: "",
            peerToPeerCount: 0,
            businessCount: 0,
            govCount: 0,
            verifiedCount:[],
            rejectedCount:[],
            totalVerified: 0,
        }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
      
      numberOfPoid(){
        return this.poids.length + 2
      },
      confidenceLevel(){
       return this.totalVerified / this.numberOfPoid * 100
      },
    },
    methods: {
        getMaritalStatusString(status) {
            if(status == 'M') return "Married";
            if(status == 'S') return "Single";
            if(status == 'D') return "Divorce";
        },
        changeTab(tab) {
            this.activeTab = tab
        },
        getPoids() {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/poids',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    const uniquePoids = response.data.filter((item, index, self) =>
                        index === self.findIndex((t) => t.poidType === item.poidType)
                    );
                    this.poids = uniquePoids;


                    response.data.forEach((value) => {
                       
                      if(value.poidType == "SELFIE" 
                        ) {

                          this.getSelfie(value.poidKey)

                      } 
                    })
                    console.log('poids (no duplicates by poidType):', uniquePoids);
                })
                .catch((error) => {
                    
                });
        },

        getSelfie(key) {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/poids/image/' + key,
            };
            this.axios(options)
                .then((response) => { 

                    this.pfpString = response.data
                })
                .catch((error) => {
                    
                });
        },

        generateKey() {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/chain/generatekey',
            };
            this.axios(options)
                .then((response) => {
                    this.tokenId = response.data.tokenId
                })
                .catch((error) => {
                    
                });
        },
        getVerifications() {
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/chain/verification/list',
            };
            this.axios(options)
                .then((response) => {
                   this.getVerificationsHistory();
                })
                .catch((error) => {
                    
                });
        },
        viewLink(){
            window.location.href="/publicLink"
        },
        getVerificationsHistory() {
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/chain/verification/history',
            } ;

   

            this.axios(options)
                .then((response) => {
                   //this.historyYES = response.data.filter(item => item.symbol === "YES");
                  // this.historyNAY = response.data.filter(item => item.symbol === "NAY");
                    this.peerToPeerCount = 0;
                    this.businessCount = 0;
                    this.govCount = 0;

                    let emailVerifiedCount = 0;
                    let emailRejectedCount = 0;

                    let bdayVerifiedCount = 0;
                    let bdayRejectedCount = 0;

                    let mobileVerifiedCount = 0;
                    let mobileRejectedCount = 0;

                    let genderVerifiedCount = 0;
                    let genderRejectedCount = 0;

                    let citizenshipVerifiedCount = 0;
                    let citizenshipRejectedCount = 0;

                    let passportVerifiedCount = 0;
                    let passportRejectedCount = 0;

                    let homeassetVerifiedCount = 0;
                    let homeassetRejectedCount = 0;

                    let educationVerifiedCount = 0;
                    let educationRejectedCount = 0;

                    let employmentVerifiedCount = 0;
                    let employmentRejectedCount = 0;

                    let vehicleVerifiedCount = 0;
                    let vehicleRejectedCount = 0;

                    let nbiVerifiedCount = 0;
                    let nbiRejectedCount = 0;

                    let votersVerifiedCount = 0;
                    let votersRejectedCount = 0;
                    
                    let civilStatusVerifiedCount = 0;
                    let civilStatusRejectedCount = 0;

                    let fullnameVerifiedCount = 0;
                    let fullnameRejectedCount = 0;

                    let selfieVerifiedCount = 0;
                    let selfieRejectedCount = 0;

                    response.data.forEach((el, i) => {
                        if(el.verifierType == 'I') {
                            this.peerToPeerCount++;
                        }

                        if(el.verifierType == 'B') {
                            this.businessCount++;
                        }

                        if(el.verifierType == 'G') {
                            this.govCount++;
                        }


                        if(el.symbol == 'YES') {

                            this.totalVerified++;
                            if(el.idType == 'EMAIL') {
                                emailVerifiedCount++
                            }

                            if(el.idType == 'BDAY') {
                                bdayVerifiedCount++
                            }

                            if(el.idType == 'MOBILE') {
                                mobileVerifiedCount++
                            }


                            if(el.idType == 'GENDER') {
                                genderVerifiedCount++
                            }

                            if(el.idType == 'CITIZENSHIP') {
                                citizenshipVerifiedCount++
                            }

                            if(el.idType == 'PASSPORTNUMBER') {
                                passportVerifiedCount++
                            }

                            if(el.idType == 'HOMEASSET') {
                                homeassetVerifiedCount++
                            }

                            if(el.idType == 'EDUCATION') {
                                educationVerifiedCount++
                            }

                            if(el.idType == 'EMPLOYMENTCERT') {
                                employmentVerifiedCount++
                            }

                            if(el.idType == 'VEHICLEASSET') {
                                vehicleVerifiedCount++
                            }

                            if(el.idType == 'NBICLEARANCE') {
                                nbiVerifiedCount++
                            }

                            if(el.idType == 'VOTERSID') {
                                votersVerifiedCount++
                            }

                            if(el.idType == 'CIVILSTATUS') {
                                civilStatusVerifiedCount++
                            }

                            if(el.idType == 'FULLNAME') {
                                fullnameVerifiedCount++
                            }

                            if(el.idType == 'SELFIE') {
                                selfieVerifiedCount++
                            }
                        }

                        if(el.symbol == 'NAY') {

                            if(el.idType == 'EMAIL') {
                                emailRejectedCount++
                            }

                            if(el.idType == 'BDAY') {
                                bdayRejectedCount++
                            }

                            if(el.idType == 'MOBILE') {
                                mobileRejectedCount++
                            }

                            if(el.idType == 'GENDER') {
                                genderRejectedCount++
                            }

                            if(el.idType == 'CITIZENSHIP') {
                                citizenshipRejectedCount++
                            }

                            if(el.idType == 'PASSPORTNUMBER') {
                                passportRejectedCount++
                            }

                            if(el.idType == 'HOMEASSET') {
                                homeassetRejectedCount++
                            }

                            if(el.idType == 'EDUCATION') {
                                educationRejectedCount++
                            }

                            if(el.idType == 'EMPLOYMENTCERT') {
                                employmentRejectedCount++
                            }

                            if(el.idType == 'VEHICLEASSET') {
                                vehicleRejectedCount++
                            }

                            if(el.idType == 'NBICLEARANCE') {
                                nbiRejectedCount++
                            }

                            if(el.idType == 'VOTERSID') {
                                votersRejectedCount++
                            }

                            if(el.idType == 'CIVILSTATUS') {
                                civilStatusRejectedCount++
                            }

                            if(el.idType == 'FULLNAME') {
                                fullnameRejectedCount++
                            }

                            if(el.idType == 'SELFIE') {
                                selfieRejectedCount++
                            }
                        }

         

                        
                    })

                    this.$set(this.verifiedCount, "EMAIL", emailVerifiedCount);
                    this.$set(this.rejectedCount, "EMAIL", emailRejectedCount);

                    this.$set(this.verifiedCount, "BDAY", bdayVerifiedCount);
                    this.$set(this.rejectedCount, "BDAY", bdayRejectedCount);

                    this.$set(this.verifiedCount, "MOBILE", mobileVerifiedCount);
                    this.$set(this.rejectedCount, "MOBILE", mobileRejectedCount);

                    this.$set(this.verifiedCount, "GENDER", genderVerifiedCount);
                    this.$set(this.rejectedCount, "GENDER", genderRejectedCount);

                    this.$set(this.verifiedCount, "CITIZENSHIP", citizenshipVerifiedCount);
                    this.$set(this.rejectedCount, "CITIZENSHIP", citizenshipRejectedCount);

                    this.$set(this.verifiedCount, "PASSPORTNUMBER", passportVerifiedCount);
                    this.$set(this.rejectedCount, "PASSPORTNUMBER", passportRejectedCount);

                    this.$set(this.verifiedCount, "HOMEASSET", homeassetVerifiedCount);
                    this.$set(this.rejectedCount, "HOMEASSET", homeassetRejectedCount);

                    this.$set(this.verifiedCount, "EDUCATION", educationVerifiedCount);
                    this.$set(this.rejectedCount, "EDUCATION", educationRejectedCount);

                    this.$set(this.verifiedCount, "EMPLOYMENTCERT", employmentVerifiedCount);
                    this.$set(this.rejectedCount, "EMPLOYMENTCERT", employmentRejectedCount);

                    this.$set(this.verifiedCount, "VEHICLEASSET", vehicleVerifiedCount);
                    this.$set(this.rejectedCount, "VEHICLEASSET", vehicleRejectedCount);

                    this.$set(this.verifiedCount, "NBICLEARANCE", nbiVerifiedCount);
                    this.$set(this.rejectedCount, "NBICLEARANCE", nbiRejectedCount);

                    this.$set(this.verifiedCount, "VOTERSID", votersVerifiedCount);
                    this.$set(this.rejectedCount, "VOTERSID", votersRejectedCount);

                    this.$set(this.verifiedCount, "CIVILSTATUS", civilStatusVerifiedCount);
                    this.$set(this.rejectedCount, "CIVILSTATUS", civilStatusRejectedCount);

                    this.$set(this.verifiedCount, "FULLNAME", fullnameVerifiedCount);
                    this.$set(this.rejectedCount, "FULLNAME", fullnameRejectedCount);

                    this.$set(this.verifiedCount, "SELFIE", selfieVerifiedCount);
                    this.$set(this.rejectedCount, "SELFIE", selfieRejectedCount);
 

                })
                .catch((error) => {
                    console.log(error)
                });
        },
        formatAmount:  function (num) {
            if(isNaN(num)) {
            num = 0;
            }
            return parseFloat(num).toFixed(8)
        },
        formatDate: function(time){
            return new Date(time).toLocaleString();
        },
        openSidebar() {
            this.sideBar = !this.sideBar
        },
        privateKey() {
            this.modalState = 'privateKey'
        },
        changePin() {
            this.$bvModal.show('change-pin-modal'); 
            this.modalState = 'changePin'
        },
        exitModal() {
            this.modalState = 'main'
        },
        viewPrivateKey() {
            this.init.viewPrivKey.state = 'pin';
            this.$bvModal.show('pin-key-modal');
        },
        viewRejection(remark) {
            this.remark = remark
            this.$bvModal.show('view-rejection-modal');
        },
        importPrivateKey() {
            this.$bvModal.show('import-key-modal'); 
        },
        changeState(state) {
            this.state = state
        },
        profile() {
            this.$router.push('/profile')
        },
        transactions() {
            this.$router.push('/requests')
        },
        share() {
            this.$bvModal.show('share-details-modal'); 
        },
        initContract: function(type) {
            this.ifAddingAsset = true
            if(type == 'token') {
                this.isNft = false
            } else if(type == 'nft') {
                this.isNft = true
            }
            console.log(this.isNft)
        },
        closeContract() {
            this.ifAddingAsset = false
            this.noAsset = false
        },
        send: function() {
            this.$bvModal.show('send-modal'); 
        },
        sendAsset: function(info) {
            this.$bvModal.show('send-asset-modal'); 
            this.selectedAsset = info
        },
        receive: function() {
            this.$bvModal.show('deposit-modal'); 
        },
        exchange: function(){
            this.$bvModal.show('exchange-modal'); 
        },
        logout: function(){
            this.$bvModal.show('logout-modal'); 
        },
        viewSubCard(selected, ifNft) {
            if(ifNft == false) {
                this.$router.push({ name: 'SubAssetDashboard', params: {token: selected}})
            } else if (ifNft == true) {
                this.$router.push({ name: 'SubNftDashboard', params: {nft: selected}})
            } else {
                console.log(ifNft)
                console.log(selected)
            }
        },
        getPfp() {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/showavatar',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    this.pfpString = response.data
                })
                .catch((error) => {
                    
                });
        },
        checkSession: function() {
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/sessioncheck',
            };

            this.axios(options)
            .then((response) => { 
                this.getProfile();
                }).catch((err) => {
                    
                if(err.response.data.msgCode == '00001') {
                    window.location.href = "/login";
                }  

                if(err.response.data.msgCode == '00020') {

                    setTimeout( () => {
                    window.location.href = "/setup-profile";
                },1000)
                    
                }  

                })
        },

        getAddressYes: function(){
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/address/YES',
            };

            this.axios(options)
                .then((response) => {
                    this.init.address = response.data
                    this.getVerifications()
                }).catch((err) => {
                    
                    console.log(err.response.data)     

                    
                })
        },

        getAddressNay: function(){
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/address/NAY',
            };

            this.axios(options)
                .then((response) => {
                    // this.init.address = response.data;
                    //console.log(this.init.address)
                    // this.getFund();
                    this.getRejections();
                    // this.getSubwalletInfo();
                }).catch((err) => {
                    
                    console.log(err.response.data)     

                    
                })
        },

        getVerifications: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                //url: '/blx-api/address/BLX/' + this.init.address.addressValue,
                url: '/blx-api/address/YES/' + this.init.address.addressValue,
            };

            this.axios(options)
                .then((response) => {
                    this.getVerificationsHistory()
                this.init.verifications = response.data;
                this.init.verifications.history.forEach((value, index) => {
                    if(value.confirmations >= 2) {
                        console.log(value.confirmations)
                        this.status = "COMPLETED"
                    } else {
                        console.log('error')
                        this.status = "PENDING"
                    }

    

                });

                // console.log(this.init.fund.balance);

                this.init.verifications.history.sort(function(a, b) {

                    return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
                });

                this.init.verifications.history 
                }).catch((err) => {
                    
                    console.log(err.response.data)     

                    
                })
        },

        getRejections: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                //url: '/blx-api/address/BLX/' + this.init.address.addressValue,
                url: '/blx-api/address/NAY/' + this.init.address.addressValue,
            };

            this.axios(options)
                .then((response) => {
                
                this.init.rejections = response.data;
                this.init.rejections.history.forEach((value, index) => {
                    if(value.confirmations >= 2) {
                        console.log(value.confirmations)
                        this.status = "COMPLETED"
                    } else {
                        console.log('error')
                        this.status = "PENDING"
                    }

                });

                // console.log(this.init.fund.balance);

                this.init.rejections.history.sort(function(a, b) {

                    return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
                });
                }).catch((err) => {
                    
                    console.log(err.response.data)     

                    
                })
        },


        getProfile: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/profile/',
            };

            this.axios(options)
                .then((response) => {

                    console.log(response.data.submittedPoidCount > 1)
                if(response.data.submittedPoidCount > 1) {
                    this.init.profile = response.data;
                    this.init.entityId = this.init.profile.entityId;
                } else {
                    this.$router.push('/setup-profile')
                }
    


                    
                })
        },

    },
    mounted() {
        this.checkSession();
        //this.getAddressYes();
        //this.getAddressNay();
        //this.getPfp();
        this.getPoids();
        this.getVerificationsHistory();
        //this.getVerifications();
        let socketScript = document.createElement('script')
            socketScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.3.0/socket.io.js')
            document.head.appendChild(socketScript)
        //console.log(this.init.fund.history.confirmations)
    }
}
</script>

<style scoped lang="scss">

.profile-picture {
  width: 100px; 
  height: 100px;
  border-radius: 3px; 
  overflow: hidden; 
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; 
}

    .wrapper {
        position:relative;
        .sidebar {
            position:fixed;
            padding:20px;
            width:20em;
            background-color:#000;
            height:100vh;
            z-index:2;
            .sidebar-link {
                margin:20px auto;
                width: 15em;
                cursor:pointer;
                &.active {
                    border-right:2px #0e649e solid;
                }
                .sidebar-link-img {
                    width:20px;
                    object-fit:contain;
                    height:auto;
                    margin-right:4px;
                }
                .sidebar-link-text {
                    color:white;
                    margin-left:4px;
                    margin-top:13px;
                }
                .arrow-down {
                    width:15px;
                    object-fit:contain;
                    height:auto;
                }
            }
        }
        .key-choices {
            position: absolute;
            background: #fff;
            color: #333;
            top: 60px;
            overflow: auto;
            z-index: 3;
            width:100%;
            border-radius:10px;
            .choice {
                width:100%;
                padding:10px;
                &:hover {
                    background-color:blue;
                    color:white;
                }
            }
        }
        .main-body {
            width:100%;
            padding:50px 70px;
            .sidebar-toggler {
                background:none;
                width:2.5em;
                height:2.5em;
                border:#fff 1px solid;
                border-radius:5px;
                fill:white;
            }
            .search-bar {
                width:20em;
            }
            .left-section {
                .topbar-img {
                    object-fit: contain;
                }
                .profile {
                    .profile-name {
                        color:white;
                        margin-bottom:0;
                        font-family: 'SSN-Bold';
                    }
                    .profile-email {
                        color:white;
                        font-family: 'SSN-Light';
                        margin-bottom:0;
                    }
                }
            }
            .sidebar-link-img {
                    width:20px;
                    object-fit:contain;
                    height:auto;
                    margin-right:4px;
                }
            .tabs {
                width: 50em;
                .tab {
                    cursor:pointer;
                    color:#fff;
                    font-family:SSN-Roman;
                    &.active {
                        color:#0567d7;
                    }
                    h6 {
                        margin-bottom:2px;
                    }
                }
                .active-circle {
                    width:10px;
                    height:10px;
                    background: #0567d7;
                    border-radius:50%;
                }
            }
            .main-wallet {
                width:100%;
                .webview-id-card {
                    width:50em;
                    background-image: url('../assets/ID-bg.png');
                    background-size:cover;
                    background-repeat:no-repeat;
                    border-radius:10px;
                    padding:20px;
                }
                .history {
                    width:35em;
                    padding:15px 30px;
                    background-image: linear-gradient(to right, #110737, #150754);
                    border-radius:10px;
                    .transactions {
                        width:100%;
                        .transaction {
                            background: rgba(125, 125, 125, 0.5);
                            padding:15px;
                            border-radius:10px;
                            .dot {
                                object-fit:contain;
                                width:15px;
                                height:15px;
                            }
                            .transaction-info {
                                font-family:SSN-Roman;
                                .hash {
                                    white-space: nowrap !important;
                                    width: 20em;
                                    overflow: hidden !important;
                                    text-overflow: ellipsis !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>